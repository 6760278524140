
import { defineComponent } from "vue";

export default defineComponent({
  name: "Error",
  props: {
    errorId: {
      default: new Date()
        .toISOString()
        .split("T")[1]
        .replaceAll(":", "")
        .replace(".", "")
        .replace("Z", ""),
      type: String,
    },
    errorMessage: {
      default: "The server cannot understand the request",
      type: String,
    },
  },
  setup () {
    return {};
  },
});
